import { SvelteToast } from "@zerodevx/svelte-toast"
import { createInertiaApp } from "@inertiajs/inertia-svelte"
import { InertiaProgress } from "@inertiajs/progress"

import "./application.css"
document.addEventListener("DOMContentLoaded", () => {
  new SvelteToast({ target: document.getElementById("toast-cont") })
})

const pages = import.meta.glob("../Pages/**/*.svelte")

InertiaProgress.init()

// <svelte:component this={selected.component}/>

if (document.querySelector("#app")) {
  createInertiaApp({
    resolve: async (name) => {
      if (name) {
        const page = await pages[`../Pages/${name}.svelte`]()
        return page
      } else {
        window.location.reload()
        return
      }
    },
    setup(resp) {
      const { el, App, props } = resp
      const app = new App({ target: el, props })
    }
  })
}

InertiaProgress.init({
  // The delay after which the progress bar will
  // appear during navigation, in milliseconds.
  delay: 50,

  // The color of the progress bar.
  color: "#29d",

  // Whether to include the default NProgress styles.
  includeCSS: true,

  // Whether the NProgress spinner will be shown.
  showSpinner: false
})
